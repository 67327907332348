// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-error-page-js": () => import("./../../../src/pages/error-page.js" /* webpackChunkName: "component---src-pages-error-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-payment-status-js": () => import("./../../../src/pages/payment-status.js" /* webpackChunkName: "component---src-pages-payment-status-js" */),
  "component---src-pages-send-data-js": () => import("./../../../src/pages/send-data.js" /* webpackChunkName: "component---src-pages-send-data-js" */),
  "component---src-templates-constructor-js": () => import("./../../../src/templates/constructor.js" /* webpackChunkName: "component---src-templates-constructor-js" */),
  "component---src-templates-membership-fitness-js": () => import("./../../../src/templates/membership-fitness.js" /* webpackChunkName: "component---src-templates-membership-fitness-js" */),
  "component---src-templates-membership-methodic-js": () => import("./../../../src/templates/membership-methodic.js" /* webpackChunkName: "component---src-templates-membership-methodic-js" */),
  "component---src-templates-membership-pool-js": () => import("./../../../src/templates/membership-pool.js" /* webpackChunkName: "component---src-templates-membership-pool-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

